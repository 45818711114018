.airport-live {
    text-align: center;
    justify-content: center;
    margin: -10px -10px;
    font-family: 'noto_sansregular', sans-serif;
    height: 100vh;
    overflow: hidden;

    .container {
        max-width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        justify-content: center;
        height: 60%;
        overflow: hidden;
        font-size: 16px;

        @media (orientation:landscape) {
            @media (min-width:768px) {
                font-size: 14px;
            }
            @media (min-width:1024px) {
                font-size: 16px;
            }
            @media (min-width:1200px) {
                font-size: 16px;
            }
            @media (min-width:1900px) {
                font-size: 24px;
            }
        }

        @media (orientation:portrait) {

        @media (min-width:768px) {
            font-size: 20px;
        }
        @media (min-width:1024px) {
            font-size: 52px;
            height: 65%;
        }
        @media (min-width:1900px) {
            font-size: 96px;
        }
    }


        .upper-section {
            background: #F6AA21;
            color: white;
            font-size: 1.35em;
            padding: 20px 0px;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .heading {
                font-size: 1.57em;
                font-weight: bold;
                padding: 5px 2px;
                font-family: 'noto_sansbold' , sans-serif;
            }

            .sub-heading {
                margin-bottom: 30px;
                font-family: 'noto_sansbold' , sans-serif;
            }
            .bus-stop-list {
                padding-top: 15px;
                .bus-stop {
                    font-family: 'noto_sansbold' , sans-serif;
                    .stop{
                        padding: 70px 10px;
                        .hr-time{
                            margin-right: 10px;
                        }
                    }
                    .stop-name{
                        text-align: left;
                        padding-right: 1em;
                    }
                    .stop-time{
                        flex: 1;
                        text-align: left;
                        padding-left: 1em;
                        }
                }
            }
            .current-time{
                font-size: 0.55em;
                margin: 40px 0px 10px 0px;
                text-align: center;
                font-family: 'noto_sansbold' , sans-serif;

            }
        }
        .lower-section{
            text-align: center;
            position: fixed;
            bottom: 30px;
            width: 100%;
            .book{
                font-weight: bold;
                font-family: 'noto_sansbold' , sans-serif;
            }

            .description{
                font-size: 1.25em;
                color: #F6AA21;
                width: 100%;
                padding: 15px 10px;
            }
            .counter{
                font-size: 0.75em;
            }
        }
    }
    .bus-section {
        top:38%;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        display: flex;
        justify-content: center;

        @media (orientation: portrait) {
            top: 49%;
        }

        img{
            height: 90%;
            max-width: 100%;

            @media (orientation: portrait) {
                height: 64%;
            }
    }
    }
}