@import '../../css/mixin.scss';

.loader-container{
  margin:0px;
  background-color: yellow;
  .MuiBox-root{
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color:white;
    box-sizing: unset;
  };
.custom-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border:  white;
};
}

