.airport-live-horizontal {
    margin: -10px -10px;
    font-family: 'noto_sansregular',sans-serif;
    height: 100vh;

    .container {
        max-width: 100%;
        margin: 0;
        padding: 0;
        height: 80%;
        overflow: hidden;
        font-size: 16px;

        @media (orientation:landscape) {
            @media (min-width:768px) {
                font-size: 14px;
            }

            @media (min-width:1024px) {
                font-size: 16px;
            }

            @media (min-width:1200px) {
                font-size: 16px;
            }

            @media (min-width:1900px) {
                font-size: 24px;
            }
        }

        @media (orientation:portrait) {

            @media (min-width:768px) {
                font-size: 20px;
            }

            @media (min-width:1024px) {
                font-size: 52px;
                height: 65%;
            }

            @media (min-width:1900px) {
                font-size: 96px;
            }
        }


        .upper-section {
            background: #F6AA21;
            color: white;
            font-size: 1.35em;
            padding: 20px 0px;
            height: 100%;
            display: flex;
            flex-direction: row;

            .left-section {
                width: 50%;
                text-align: center;
                line-height: 3.1em;

                .heading {
                    font-size: 3.3em;
                    font-weight: bold;
                    font-family: 'noto_sansbold',sans-serif;
                    margin-top: 80px;

                }

                .sub-heading {
                    margin-bottom: 30px;
                    font-family: 'noto_sansbold',sans-serif;
                    font-size: 2.5em;
                }

                .bus-section {
                    height: 65%;
                    width: 63%;
                    top: 30%;
                    left: -5%;
                    position: absolute;
                    @media (max-height: 910px) {
                        height: 64%;
                        width: 62%;
                        top: 34%;
                     }
                     @media (max-height: 830px) {
                        height: 63%;
                        width: 61%;
                        top: 35%;
                     }

                    img {
                        height: 100%;
                        width: 100%;

                    }
                }
            }

            .right-section {
                width: 50%;
                text-align: center;
                align-items: center;
                display: flex;
                flex-direction: column;

                .bus-stop-list {
                    width: 90%;
                    margin-left: 10%;

                    padding-top: 22%;
                    @media (max-height: 1060px) {
                        padding-top: 19%;
                     }

                    .bus-stop {

                        font-family: 'noto_sansbold',sans-serif;
                        font-size: 2em;
                     @media (max-height: 910px) {
                        font-size:1.73em;
                     }
                     @media (max-height: 810px) {
                        font-size:1.6em;
                     }


                        .stop {
                            padding-bottom: 144px;
                            @media (max-height: 890px) {
                                padding-bottom: 138px;
                         }
                        
                         @media (max-height: 1060px) {
                            padding-bottom: 138px;
                         }

                            .hr-time {
                                margin-right: 10px;
                            }
                        }

                        .stop-name {
                            text-align: left;

                        }

                        .stop-time {
                            flex: 1;
                            text-align: left;
                            padding-left: 1em;

                        }

                    }
                }

                .current-time {
                    font-size: 1.2em;
                    text-align: center;
                    font-family: 'noto_sansbold',sans-serif;
                    color: black;
                    position: fixed;
                    top: 68%;
                      @media (max-height: 910px) {
                        font-size: 1em;
                        top: 69%;
                     }
                    

                }
            }

        }

        .lower-section {
            position: fixed;
            bottom: 45px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            @media (max-height: 890px) {
                    bottom: 23px;
             }
            
             @media (max-height: 1060px) {
                    bottom: 31px;
             }
            .lower-section-right{
              width: 100%;
            }

            .book {
                font-weight: bold;
                font-family: 'noto_sansbold',sans-serif;
            }

            .description {
                font-size: 2.25em;
                width: 100%;
                text-align: center;
                @media (max-height: 1060px) {
                        font-size: 2em;
                    }
                
                 @media (max-height: 850px) {
                        font-size: 1.8em;
                }
            }

            .counter {
                font-size: 0.75em;
            }
        }
    }

}